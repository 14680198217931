<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>

    <div class="container">
      <div class="left">
        <div class="top">
          <img src="./images/banner_top.png" alt="" />
        </div>
        <div :class="['banner']">
          <div class="banner-box">
            <!-- 无图片时 -->
            <div v-if="list.length == 0" class="banner_null">
              <i class="el-icon-plus"></i>
            </div>
            <!-- 有图片时 -->
            <div v-else>
              <el-carousel :interval="2000" height="130px" arrow="never">
                <el-carousel-item v-for="(item, i) in list" :key="i">
                  <img :src="item.url" />
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <el-divider content-position="center">首页banner设置</el-divider>
        <!-- <el-empty v-if="!show" description="点击左侧虚线区域进行配置"></el-empty> -->
        <el-form ref="form" :model="formData" label-width="80px">
          <div>
            <div class="banner">
              <!-- <el-button style="margin-bottom: 10px" type="primary" size="small" icon="el-icon-plus" @click="addBanner">新增一张图片 </el-button> -->
              <el-dropdown style="margin-bottom: 10px" @command="handleClickAdd">
                <el-button size="small" type="primary"> 新增<i class="el-icon-arrow-down el-icon--right"></i> </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="1">优惠券</el-dropdown-item>
                  <el-dropdown-item command="2">通知公告</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <div class="banner-title">
                <span>序号</span>
                <span>优惠券/通知公告</span>
                <span>
                  <div>图片</div>
                  <div style="font-size: 12px">(建议尺寸：221 * 70)</div>
                </span>
                <span>排序</span>
                <span>操作 </span>
              </div>
              <div class="banner-content" v-for="(banner, i) in list" :key="i">
                <span>{{ i + 1 }}</span>
                <span>
                  <SelectCoupon v-if="banner.type == 1" width="150px" v-model="banner.coupon_id"></SelectCoupon>
                  <span class="notice" v-if="banner.type == 2" @click="editNotice(banner, i)">{{
                    banner.content ? '编辑' : '新增'
                  }}</span>
                </span>
                <span style="padding-top: 10px">
                  <UploadImg :url.sync="banner.url"></UploadImg>
                </span>
                <span>
                  <el-input-number
                    style="width: 120px"
                    v-model="banner.sort"
                    :step="1"
                    :min="0"
                    step-strictly
                    placeholder="请输入"
                    size="small"
                  ></el-input-number>
                </span>
                <span>
                  <el-link style="margin-right: 20px" type="primary" @click="deleteBanner(i)">删除</el-link>
                </span>
              </div>
              <el-empty v-if="list.length == 0" description="暂无图片"></el-empty>
            </div>
          </div>
          <el-form-item label="" label-width="0" style="margin-top: 20px; text-align: center">
            <el-button type="" size="small" @click="getBannerList">取消</el-button>
            <el-button v-checkbtn="isShowBtn(AUTH_BTN.banner_list_add)" type="primary" size="small" @click="save">提交</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 弹窗 -->
    <el-dialog title="编辑公告" :visible.sync="show_notice" width="width">
      <div>
        <Editor ref="editor" :showBtn="false" :content.sync="content"></Editor>
      </div>
      <div slot="footer">
        <el-button @click="show_notice = false">取 消</el-button>
        <el-button type="primary" @click="confirmNotice">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { authBtnMixin } from '@/mixins/authBtnMixin'
import { getBannerListAPI, updateBannerAPI } from './api'
import UploadImg from '@/components/uploader/uploader-img.vue'
import SelectCoupon from '@/views/components/select-coupon.vue'
import Editor from '@/components/editor/index.vue'
export default {
  name: 'BannerSetting',
  mixins: [authBtnMixin],
  components: { UploadImg, SelectCoupon, Editor },
  data() {
    return {
      show_notice: false,
      list: [],
      formData: {},
      content: '',
      currentIndex: ''
    }
  },
  computed: {},
  watch: {},
  mounted() {
    this.getBannerList()
  },

  methods: {
    async getBannerList() {
      this.list = await getBannerListAPI({ type: 'mini_index' })
    },
    handleClickAdd(e) {
      if (e == 1) {
        // 优惠券
        let data = {
          type: 1,
          url: '',
          go_path: '',
          sort: '1',
          coupon_id: '',
          content: ''
        }
        this.list.push(data)
      }
      if (e == 2) {
        // 公告
        let data = {
          type: 2,
          url: '',
          go_path: '',
          sort: '1',
          content: '',
          coupon_id: ''
        }
        this.list.push(data)
      }
    },
    // 编辑公告
    editNotice({ content }, i) {
      console.log(content, i)
      this.currentIndex = i
      this.content = content ? content : '<p><br></p>'
      this.show_notice = true
    },
    confirmNotice() {
      this.list[this.currentIndex].content = this.content == '<p><br></p>' ? '' : this.content
      this.show_notice = false
    },
    // 添加一张banner
    // addBanner() {
    //   let data = { url: '', go_path: '', sort: '1', coupon_id: '' }
    //   this.list.push(data)
    // },
    // 删除一张banner
    deleteBanner(i) {
      this.list.splice(i, 1)
    },
    // 提交
    save() {
      const url_null = this.list.some(item => !item.url)
      const coupon_null = this.list.some(item => !item.coupon_id && item.type == 1)
      if (url_null) return this.$message.warning('banner图片不能为空')
      if (coupon_null) return this.$message.warning('优惠券不能为空')
      let data = {
        type: 'mini_index',
        data: this.list
      }
      updateBannerAPI(data).then(() => {
        this.$message.success('保存成功')
        this.getBannerList()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .container {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    .left {
      margin-right: 40px;
      width: 375px;
      min-height: 600px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      .top {
        img {
          width: 100%;
        }
      }
      .banner {
        background: url('./images/banner_main.png') no-repeat;
        background-size: cover;
        padding: 0 8px;
        min-height: 670px;
        padding-top: 10px;
        .banner-box {
          cursor: pointer;
          background-color: #fff;
          width: 100%;
          height: 130px;
          box-sizing: border-box;
          border: 2px dashed #409eff;
          border-radius: 10px;
          .banner_null {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            background-color: #fff;
            i {
              display: block;
              font-size: 30px;
              color: #888;
            }
          }
          img {
            display: block;
            border-radius: 10px;
            width: 100%;
            height: 96%;
          }
        }
      }

      .select-border {
        border: 2px dashed #f56c6c !important;
      }
    }

    .right /deep/ {
      flex: 1;
      min-height: 600px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

      .el-form {
        padding: 0 10px;

        .el-form-item {
          margin-bottom: 0;
        }

        .banner {
          .banner-title {
            background-color: #f4f5f9;
            padding: 10px 20px;
            display: flex;
            align-items: center;

            & > span {
              padding: 0 20px;
              &:nth-child(1) {
                width: 100px;
              }
              &:nth-child(2) {
                flex: 1;
              }
              &:nth-child(3) {
                text-align: center;
                flex: 1;
              }
              &:nth-child(4) {
                flex: 1;
              }
              &:nth-child(5) {
                min-width: 100px;
                max-width: 200px;
              }
            }
          }

          .banner-content {
            display: flex;
            align-items: center;
            background-color: #fbfbfb;
            padding: 10px 20px;
            border-bottom: 1px solid #e4e5e9;

            & > span {
              padding: 0 20px;
              &:nth-child(1) {
                width: 100px;
              }
              &:nth-child(2) {
                display: flex;
                align-items: center;
                flex: 1;
              }
              &:nth-child(3) {
                flex: 1;
              }
              &:nth-child(4) {
                flex: 1;
              }
              &:nth-child(5) {
                min-width: 100px;
                max-width: 200px;
              }
            }
            .notice {
              display: inline-block;
              max-width: 200px;
              color: #409eff;
              cursor: pointer;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }

      .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 120px !important;
        height: 60px !important;
        line-height: 60px !important;
        text-align: center;
      }

      .avatar {
        width: 120px !important;
        height: 60px !important;
        display: block;
      }
    }
  }
}
</style>
