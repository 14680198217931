import { http } from '@/http/axios.js'
// 列表
export function getAliOssSts() {
  return http({
    url: `/misc/basic/file`,
    method: 'POST'
  })
}

export function getUploadConfigApi() {
  return http({
    url: `/company/basic/file/getUploadConf`,
    method: 'GET'
  })
}
