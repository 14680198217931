import { http } from '@/http/axios.js'
export function getBannerListAPI(params) {
  return http({
    url: '/company/basic/decorateSetting/getSettingData',
    method: 'get',
    params
  })
}
// edit
export function updateBannerAPI(data) {
  return http({
    url: '/company/basic/decorateSetting/setting',
    method: 'post',
    data
  })
}
