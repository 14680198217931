import { http } from '@/http/axios.js'
// 地址树
export function getAreaTree(params) {
  return http({
    url: '/company/area/tree',
    method: 'GET',
    params
  })
}
// 充电站表
export function getStationListAPI(params) {
  return http({
    url: '/company/chargingStation/list',
    method: 'get',
    params
  })
}
// 充电桩列表
export function getPileListAPI(params) {
  return http({
    url: '/company/charging/chargingPile/list',
    method: 'get',
    params
  })
}
// 充电枪列表
export function getGunListAPI(params) {
  return http({
    url: '/company/chargingGun/list',
    method: 'get',
    params
  })
}
// 地锁列表
export function getLockListAPI(params) {
  return http({
    url: '/company/chargingLock/list',
    method: 'get',
    params
  })
}
// 停车位列表
export function getSpaceListAPI(params) {
  return http({
    url: '/company/parkingSpace/list',
    method: 'get',
    params
  })
}
// 角色列表
export function getRoleList(params) {
  return http({
    url: '/company/basic/rdbcRole/list',
    method: 'GET',
    params
  })
}
// 品牌列表
export function getBrandListAPI(params) {
  return http({
    url: '/company/car/brand/list',
    method: 'get',
    params
  })
}
// 优惠券列表
export function getCouponListAPI(params) {
  return http({
    url: '/company/basic/marketing/coupon/list',
    method: 'get',
    params
  })
}